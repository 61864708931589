// src/network/config.js

import axios from 'axios';
import { BACKEND_URL } from '../constants';

export const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
  // timeout: 100000,
});
axiosInstance.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('userToken') || sessionStorage.getItem('userToken')) {
      const userToken = JSON.parse(localStorage?.getItem('userToken') || sessionStorage?.getItem('userToken'));
      config.headers.Authorization = `Bearer ${userToken}`;
      // config.headers['Content-Type'] = 'application/json';
      config.headers.lang = localStorage.getItem('i18nextLng')
        ? localStorage.getItem('i18nextLng').startsWith('en')
          ? 'EN'
          : 'AR'
        : 'EN';
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);
